<template>
  <a-drawer class="coupon-drawer" :title="$t('coupon.tips')" placement="bottom" :closable="false" :visible="visible"
            @close="onClose">
    <div class="tabs">
      <input type="radio" id="radio-1" name="tabs" :checked="activeKey === 0" @change="onChangeTabHandler(0)" />
      <label class="tab" for="radio-1">{{ availableTitle }}</label>
      <input type="radio" id="radio-2" name="tabs" :checked="activeKey === 1" @change="onChangeTabHandler(1)" />
      <label class="tab" for="radio-2">{{ noAvailableTitle }}</label>
      <span class="glider"></span>
    </div>
    <div class="scroll-view">
      <div v-show="activeKey === 0" class="coupon-list">
        <div class="coupon-item flex" @click="onCouponSelect(item)" v-for="item in source.availableCouponList"
             :key="item.coupon_id">
          <div class="coupon-wrapper">
            <div class="coupon-inner">
              <div class="discount flex-center-column">
                <div class="ellipsis bold">{{ item.discount }}</div>
                <div class="ellipsis">{{ item.limit_price }}</div>
              </div>
              <div class="describe flex-center-column">
                <div class="ellipsis bold">{{ item.name }}</div>
                <div class="ellipsis time">{{ item.start_time + "-" + item.end_time }}</div>
                <div class="ellipsis text" :title="item.description">
                  {{ item.description }}
                </div>
              </div>
            </div>
          </div>
          <input class="coupon-radio" type="radio" :checked="couponcode === item.couponcode" />
        </div>
      </div>
      <div v-show="activeKey===1" class="coupon-list">
        <div class="coupon-item flex"v-for="item in source.noAvailableCouponList"
             :key="item.coupon_id">
          <div class="coupon-wrapper disabled">
            <div class="coupon-inner">
              <div class="discount flex-center-column">
                <div class="ellipsis bold">{{item.discount}}</div>
                <div class="ellipsis">{{item.limit_price}}</div>
              </div>
              <div class="describe flex-center-column">
                <div class="ellipsis bold">{{item.name}}</div>
                <div class="ellipsis time">{{item.start_time + '-' + item.end_time}}</div>
                <div class="ellipsis text" :title="item.reason">
                  {{item.reason}}
                </div>
              </div>
            </div>
          </div>
          <input class="coupon-radio" type="radio" :disabled="true"/>
        </div>
      </div>
      <div v-show="isEmpty" class="flex" style="width: 100%;height: 100%;">
        <a-empty :image="require('@/assets/coupons_none.png')">
          <span slot="description" class="empty-text"> {{$t('coupon.empty')}} </span>
        </a-empty>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { Drawer } from "ant-design-vue";
export default {
  name: "CouponDrawer",
  components: {
    [Drawer.name]: Drawer,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    couponcode: {
      type: String,
      default: "",
    },
    source: {
      type: Object,
      default: function () {
        return {
          availableCouponList: [],
          availableCouponNum: 0,
          noAvailableCouponList: [],
          noAvailableCouponNum: 0,
        };
      },
    },
  },
  computed: {
    isEmpty(){
      const { availableCouponNum,noAvailableCouponNum} = this.source
      return  this.activeKey===0&&!availableCouponNum||this.activeKey===1&&!noAvailableCouponNum
    },
    availableTitle() {
      return `${this.$t("coupon.tab_4")} (${this.source.availableCouponNum})`;
    },
    noAvailableTitle() {
      return `${this.$t("coupon.tab_5")} (${this.source.noAvailableCouponNum})`;
    },
  },
  data() {
    return {
      activeKey: 0,
    };
  },
  methods: {
    onCouponSelect(item) {
      this.$emit("select", item);
    },
    onChangeTabHandler(key) {
      this.activeKey = key;
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.coupon-drawer {
  /deep/.ant-drawer-content-wrapper {
    min-height: 60vh;
    .ant-drawer-content{
      border-radius: 20px 20px 0px 0px;
    }
    .ant-drawer-header{
      border: none;
      padding: 28px 10px 10px 10px;
      .ant-drawer-title{
        color: #222222;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
    }
    .ant-drawer-body {
      padding: 10px 10px 20px 10px;
    }
  }

  .tabs {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f3f3f3;
    border-radius: 24px;

    * {
      z-index: 2;
    }

    .glider {
      position: absolute;
      display: flex;
      height: 32px;
      width: calc(100% / 2.3);
      z-index: 1;
      border-radius: 24px;
      transition: 0.25s ease-out;
      background-color: $color;
    }

    .tab {
      user-select: none;
      cursor: pointer;
      color: #222222;
      font-size: 12px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 2);
      height: 100%;
      transition: color 0.15s ease-in;
    }

    input[type="radio"] {
      display: none;

      &:checked {
        &+label {
          color: #fff;
        }
      }
    }

    input[id="radio-1"] {
      &:checked {
        &~.glider {
          transform: translateX(7%);
        }
      }
    }

    input[id="radio-2"] {
      &:checked {
        &~.glider {
          transform: translateX(120%);
        }
      }
    }
  }
  .scroll-view {
    margin-top: 10px;
    height: 42vh;
    overflow-y: auto;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch !important;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0;
    }
    .coupon-list{
      -webkit-overflow-scrolling: touch !important;
    }
  }
  .coupon-item {
    padding: 10px;
    user-select: none;
    cursor: pointer;
    .coupon-radio {
      margin-left: 14px;
    }
    .coupon-wrapper {
      flex: 1;
      height: 160px;
      background-color: #F8F4FF;
      border-radius: 10px;
      background: radial-gradient(circle at 10px, transparent 10px, #F8F4FF 0);
      background-position: 90px calc(160px / 2);
      display: flex;

      .coupon-inner {
        flex: 1;
        padding: 10px 0;
        display: flex;
        color: #5D5D5D;
        font-size: 12px;

        .ellipsis {
          width: 100%;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //控制行数
          overflow: hidden;
        }

        .bold {
          color: #B50000;
          font-size: 20px;
          font-weight: bold;
        }

        .discount {
          width: 100px;
          padding: 0 10px;
          text-align: center;
          border-right: 2px dashed #fff;
        }

        .describe {
          flex: 1;
          padding: 0 10px;

          .bold {
            color: #222222;
            font-size: 14px;
            font-weight: bold;
          }

          .time {
            margin: 2px 0;
          }

          .text {
            line-height: 20px;
            -webkit-line-clamp: 3; //控制行数
          }
        }
      }

      .status {
        width: 24px;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        writing-mode: vertical-lr;
        writing-mode: tb-lr;
        border-radius: 0 10px 10px 0;
        background-color: #8032EA;
      }
    }

    .disabled {
      background-color: #E6E6E6;
      background: radial-gradient(circle at 10px, transparent 10px, #E6E6E6 0);
      background-position: 90px -80px;

      .coupon-inner {
        .bold {
          color: #5D5D5D;
        }

        .describe {
          .text {
            color: #B50000;
          }
        }
      }

      .status {
        color: transparent;
        background-color: #E6E6E6;
      }
    }
  }
}
</style>
