<template>
  <div id="pay">
    <div class="container">
      <div class="checkout-title">{{$t('common.pay_1')}}</div>
      <div class="checkout-content">
        <div class="subtitle">{{$t('common.pay_2')}}</div>
        <a-row :gutter="[30,10]" class="product">
          <a-col :xs="24" :sm="24" :md="24" :lg="10" :xl="8">
            <img class="product-img" :src="productInfo.cover" alt="">
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" :lg="10" :xl="8">
            <ul class="product-info">
              <li>
                <span>{{productInfo.name}}</span>
                <span>{{productInfo.spec}}</span>
              </li>
              <li>{{$t('common.pay_3')}}<span>{{ paymentInfo.last_active_date }}</span></li>
              <li>
                <span>{{ currency }} {{ productInfo.real_price||productInfo.price }}</span>
                <span 
                class="original-price" v-if="otPriceVisible">
                {{ currency }} {{ productInfo.ot_price }}
                </span>
              </li>
              <li class="product-label">
                <a-space :size="10" style="flex-wrap: wrap;">
                  <span class="label" :class="{'label-disabled':coupon.type}" v-if="hasDisPrice">
                    {{$t('common.general_discounts')}}
                  </span>
                  <span class="label" :class="{'label-disabled':coupon.type}" v-if="hasDisSpecPrice">
                    {{ $t('common.data_plan_offers') }}
                  </span>
                </a-space>
              </li>
            </ul>
          </a-col>
        </a-row>
        <!-- 优惠券列表 -->
        <div class="subtitle" v-if="!order_no">{{$t('coupon.tips')}}</div>
        <div class="coupon-pc" v-if="!order_no">
          <coupon-bar :source="couponList" :couponcode="coupon.couponcode" @select="onCouponSelect" />
        </div>
        <div class="coupon-app" v-if="!order_no" @click="couponVisible = true">
          <div class="flex-center-between">
            <div class="title">{{ $t('route.coupon') }}</div>
            <div class="flex-center-end">
              <span class="discount" v-if="coupon.discount_value>0">
                {{$t('common.pay_15')}}{{ currency }} {{ coupon.discount_value }}
              </span>
              <span v-else-if="!couponList.availableCouponNum && !couponList.noAvailableCouponNum">
                {{ $t('coupon.empty') }}
              </span>
              <span v-else-if="couponList.availableCouponNum && !coupon.couponcode">
                {{ `${$t("coupon.tab_4")} (${this.couponList.availableCouponNum})` }}
              </span>
              <span v-else>{{ $t('coupon.no_available') }}</span>
              <a-icon type="right" />
            </div>
          </div>
          <div class="tips" v-if="coupon.discount_value>0">{{ $t('coupon.auto') }}</div>
        </div>
        <!-- 支付方式 -->
        <div class="subtitle">{{$t('common.pay_4')}}</div>
        <div class="payment-wrapper">
          <div class="payment-info" v-if="isWalletPay">
            <div class="payment-title">{{$t('wallet.wallet_pay')}}</div>
            <div class="tag-wrapper">
                <div class="tag-item"
                @click="onSelectPayment({payment:'yue'})"
                :class="{'tag-active': payment === 'yue'}"
                >
                  <input type="radio" :checked="payment === 'yue'"/>
                  <img class="tag-icon" src="../assets/wallet_icon.png" alt="" srcset="">
                  <div>
                    <div class="wallet-pay flex-center-start">
                      <span class="wallet-label">{{ $t('route.wallet') }}</span>
                      <span v-if="!isBalancePay" class="insufficient-funds">
                        {{$t('wallet.insufficient_funds')}}
                      </span>
                    </div>
                    <div class="available-balance flex-center-start">
                      <span>{{$t('wallet.available_balance')}}：</span>
                      <span>{{ currency }}{{balance}}</span>
                    </div>
                  </div>
                  <a-button v-if="!isBalancePay" type="primary" size="small" class="top-up-btn" @click="handleTopUp">
                    {{ $t('wallet.top_up') }}
                  </a-button>
                </div>
              </div>
          </div>
          <div class="payment-info" v-for="(group,i) in paymentInfo.payment" :key="i">
              <div class="payment-title">{{ group.group }}</div>
              <div class="tag-wrapper">
                <div class="tag-item"
                v-for="(item,index) in group.list" :key="index" 
                @click="onSelectPayment(item)"
                :class="{'tag-active': payment === item.payment}"
                >
                  <input type="radio" :checked="payment === item.payment" />
                  <img class="tag-icon" :src="item.img" alt="" srcset="">
                  {{ item.name }}
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="checkout-footer flex-center-end">
        <div class="amount">
          <div class="flex-center-between" v-if="otPriceVisible">
            <span class="amount-label">{{$t('common.pay_14')}}</span>
            <span class="original-price">{{ currency }} {{ productInfo.ot_price }}</span>
          </div>
          <div class="flex-center-between">
            <span class="amount-label">{{$t('common.pay_5')}}</span>
            <span class="price">{{ currency }} {{ total }}</span>
          </div>
          <div class="flex-center-between" v-if="discountAmount>0">
            <span class="amount-label flex-center-end">
              <a-popover>
                <template slot="content">
                  <div class="discount-details">
                    <p class="flex-center-between" v-if="coupon.discount_value>0">
                      <span>{{ $t('route.coupon') }}</span>
                      <span class="discount-price">-{{ currency }} {{ coupon.discount_value }}</span>
                    </p>
                    <p class="flex-center-between" v-if="hasDisPrice&&!coupon.type">
                      <span> {{ $t('common.general_discounts') }} </span>
                      <span class="discount-price">-{{ currency }} {{ productInfo.dis }}</span>
                    </p>
                    <p class="flex-center-between" v-if="hasDisSpecPrice&&!coupon.type">
                      <span> {{ $t('common.data_plan_offers') }}  </span>
                      <span class="discount-price">-{{ currency }} {{ productInfo.dis_spec }}</span>
                    </p>
                  </div>
                </template>
                <a-icon type="info-circle" class="info-circle"/>
              </a-popover>
              <span>{{$t('common.pay_13')}}</span>
            </span>
            <span class="discount">-{{ currency }} {{ discountAmount }}</span>
          </div>
        </div>
        <a-button 
          type="buynow"
          :loading="isLoading" 
          @click="handleCreateOrder"
          >
          {{$t('common.pay_6')}}
        </a-button>
      </div>
    </div>
    <coupon-drawer 
      :visible="couponVisible" 
      :source="couponList" 
      :couponcode="coupon.couponcode" 
      @select="onCouponSelect"
      @close="couponVisible = false"
    />
    <pay-modal
      type="order"
      @handle="handlePayModalCallback"
      :visible="payModalVisible"
      :info="{
        currency,
        order_no,
        price:total,
        qrcode
      }"
    />
  </div>
</template>
<script>
import { mapState,mapGetters,mapActions } from "vuex";
import BigNumber from "bignumber.js";
import PayModal from '../components/PayModal.vue'
import CouponBar from '../components/CouponBar.vue'
import CouponDrawer from '../components/CouponDrawer.vue'
import { message,Popover } from 'ant-design-vue';
import { GetPaymentsAPI,GetOrderCouponsAPI,CreateOrderAPI,OrderStatusAPI } from '../api/api.js'
const INITIAL_COUPON = {
  couponcode:'',//优惠券码
  discount_value:0,//优惠券金额
  type:0//优惠券类型
}
export default {
  name:'Pay',
  components: {
    'pay-modal':PayModal,
    'coupon-bar':CouponBar,
    'coupon-drawer':CouponDrawer,
    [Popover.name]:Popover,
  },
  data() {
    return {
      productInfo: {
        price:0,//价格
        ot_price:0,//原价
        dis:0,//满减折扣价格
        dis_spec:0//促销折扣价格
      },
      order_no: "",//订单号
      payment: "",
      isLoading:false,
      paymentInfo:{},
      payModalVisible:false,
      couponVisible:false,
      qrcode: {
        url: "",
        icon: ""
      },
      coupon:INITIAL_COUPON,
      couponList:{
        availableCouponList:[],
        availableCouponNum:0,
        noAvailableCouponList:[],
        noAvailableCouponNum:0
      },
    };
  },
  computed:{
    ...mapState('user',['info']),
    ...mapGetters('user', ['isWalletPay']),
    ...mapGetters("config", ["currency"]),
    //应付金额
    total(){
      const { price,ot_price,real_price } = this.productInfo
      if(real_price) return real_price //如real_price存在（视为订单页过来的待支付订单，直接取real_price）
      const { discount_value,type } = this.coupon
      const amount = type ? ot_price : price //优惠券type为1 按原价计算
      return BigNumber(amount).minus(discount_value).toFixed(2);
    },
    //共减金额
    discountAmount(){
      const { dis,dis_spec } = this.productInfo
      const { discount_value,type } = this.coupon
      //优惠券type为1 只计算优惠券价格
      return type ? discount_value : BigNumber(dis).plus(dis_spec).plus(discount_value).toFixed(2);
    },
    //原价是否显示
    otPriceVisible(){
      const { ot_price,price,real_price } = this.productInfo
      const bigOriginalPrice = new BigNumber(ot_price);
      return bigOriginalPrice.gt(price) || bigOriginalPrice.gt(real_price)
    },
    //是否满减
    hasDisPrice(){
      return BigNumber(this.productInfo.dis).gt(0)
    },
    //是否套餐优惠
    hasDisSpecPrice(){
      return BigNumber(this.productInfo.dis_spec).gt(0)
    },
    //余额
    balance() {
      return BigNumber(this.info.now_money).toFixed(2);
    },
    //余额是否可用
    isBalancePay(){
      const result = BigNumber(this.balance).comparedTo(this.total)
      return result>=0
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    ...mapActions('user', ['GetInfo']),
    async handleCreateOrder() {
      if(!this.payment) return message.warning(this.$t('common.pay_4'))
      if(!this.isBalancePay&&this.payment === 'yue'&&this.isWalletPay) {
        message.warning(this.$t('wallet.insufficient_funds'))
        return 
      }
      const { origin } = window.location
      const { product_id,spec_id } = this.productInfo
      const { couponcode } = this.coupon
      const params = {
        payment: this.payment,
        ...(product_id&&{product_id}),
        ...(spec_id&&{spec_id}),
        ...(this.order_no&&{order_no:this.order_no}),
        ...(couponcode&&{couponcode}),
        ...(this.payment === 'paypal' && { returnUrl:`${origin}/result/` }),
      }
      try {
        this.isLoading = true
        const { data:{parameter,order_no} } = await CreateOrderAPI(params)
        this.isLoading = false
        this.order_no = order_no
        if(this.payment === 'yue') {
          this.$router.push({ name: 'Result',query:{r:'success',t:'p',order_no:this.order_no}});
          return 
        }
        if(this.payment === 'paypal') return window.location.replace(parameter);
        const { code_url } = parameter
        if(!code_url||!order_no) return message.warning('error')
        this.qrcode.url = code_url 
        this.payModalVisible = true 
      } catch (error) {
        this.isLoading = false
      }
    },
    async init(){
      // 从路由参数中获取产品信息
      // 设置产品信息query;
      await this.GetInfo()
      this.productInfo = this.$route.query
      console.log(this.productInfo)
      const { product_id, spec_id, order_no, coupon_price } = this.productInfo;

      // 获取支付信息
      const paymentInfo = await GetPaymentsAPI();
      this.paymentInfo = paymentInfo.data;

      // 如果存在订单号，则将订单号保存到组件数据中
      if (order_no) this.order_no = order_no;

      // 如果存在优惠券价格，则将其转换为数字，并保存到优惠券数据中
      if (coupon_price) this.coupon.discount_value = Number(coupon_price);

      // 如果产品信息中没有产品ID和规格ID，则直接返回，不进行后续操作（视为订单页过来的待支付订单）
      if (!product_id && !spec_id) {
        this.setPaymentDefault()
        return
      }
      
      // 获取订单可用优惠券信息
      const CouponData = await GetOrderCouponsAPI({ product_id, spec_id });
      this.couponList = CouponData.data;

      // 设置默认优惠券并更新标签显示文本
      this.setCouponDefault(CouponData.data);

      // 设置默认支付
      this.setPaymentDefault()
    },
    onCouponSelect({couponcode,discount_value,type,description}){
      if(!discount_value) return message.warning('error')
      const { dis,dis_spec } = this.productInfo;
      // 判断选中的优惠券是否与当前优惠券相同
      const isSameCoupon = couponcode === this.coupon.couponcode;
      // 判断选中的优惠券是否为type为1优惠券 （提示该券不与其它活动优惠叠加使用）
      if(type&&BigNumber(dis).gt(0)||type&&BigNumber(dis_spec).gt(0)){
        !isSameCoupon&&description&&message.warning(description)
      }
      // 如果选中的优惠券与当前优惠券相同，则将当前优惠券重置为初始状态（取消选中）
      // 否则，更新当前优惠券为选中的优惠券，并关闭优惠券可见状态（如果已经可见）
      this.coupon = isSameCoupon ? INITIAL_COUPON : { couponcode, discount_value, type };
      if (!isSameCoupon && this.couponVisible) this.couponVisible = false;
    },
    setCouponDefault(data){
      const { availableCouponList } = data;
      // 如果可用优惠券列表为空，则直接返回
      if (!availableCouponList.length) return;
      // 根据折扣值降序排序可用优惠券列表
      availableCouponList.sort((a, b) => b.discount_value - a.discount_value);
      // 取出第一个优惠券作为默认优惠券
      const { couponcode, discount_value, type } = availableCouponList[0];
      const { price, ot_price } = this.productInfo;
      // 计算原价-优惠券后的价格
      const originalPriceDiscounted = BigNumber(ot_price).minus(discount_value).toFixed(2);
      // 判断是否最优价格，如果最优价格，则不设置默认优惠券，否则设置默认优惠券
      // type为1优惠券默认不参与计算不设默认（但可以手动选中）
      const isOptimal = type ? BigNumber(originalPriceDiscounted).gt(price) : false;
      // 设置默认优惠券
      this.coupon = isOptimal ? INITIAL_COUPON : { couponcode, discount_value, type };
      if(!discount_value) this.coupon = INITIAL_COUPON
		},
    handlePayModalCallback(status){
      if(status === 'cancel'){
        this.payModalVisible = false
        this.$router.push({ name: 'Result',query:{r:'fail',t:'p',order_no:this.order_no}});
        return
      }
      if(status === 'success'){
        this.handleCheckOrder()
      }
    },
    onSelectPayment({payment,img}){
      if(!this.isBalancePay&&payment==='yue') return
      this.payment = payment
      this.qrcode.icon = img
    },
    setPaymentDefault(){
      if(this.isWalletPay&&this.isBalancePay){
        this.payment = 'yue'
      }else{
        this.payment = 'paypal'
      }
    },
    async handleCheckOrder(){
      const { data:{paid} } = await OrderStatusAPI({order_no:this.order_no})
      if(paid === 1){
        this.payModalVisible = false
        this.$router.push({ name: 'Result',query:{r:'success',t:'p',order_no:this.order_no}});
        return
      }
      message.warning(this.$t('common.pay_12'))
    },
    handleTopUp(){
      this.$router.push({ name: 'Wallet'});
    }
  },
  
};
</script>
<style lang="scss">
  .discount-details{
    p{
      margin-bottom: 6px;
      .discount-price{
        margin-left: 12px;
        color:#B50000;
      }
    }
  }
</style>
<style lang="scss" scoped>
#pay{
  font-size: 16px;
  .checkout-title {
    color: $color;
    font-size: 20px;
    font-weight: bold;
    margin: 40px 0 20px 0;
  }
  .original-price{
    color: #666;
    font-size: 18px;
    text-decoration:line-through;
  }
  .checkout-content {
    padding-bottom: 40px;
    border-bottom: 1px solid #f2f3f6;
    border-top: 1px solid #f2f3f6;

    .subtitle {
      color: #222222;
      font-size: 18px;
      font-weight: bold;
      padding: 10px 20px;
      margin: 40px 0;
      border-left: 3px solid $color;
      background-color: #f0eafb;
    }
    .product-img{
      object-fit: cover;
      border-radius: 6px;
    }
    .product-info{
      color: #222222;
      font-size: 20px;
      .original-price{
        font-size: 14px;
        margin-left: 10px; 
      }
      .product-label{
        .label{
          user-select: none;
          padding: 1px 6px;
          color: $color;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
          border: 1px solid $color;
          &:disabled{
            text-decoration: line-through;
          }
        }
        .label-disabled{
          color:#999999;
          border-color: #999999;
          text-decoration: line-through;
        }
      }
      li{
        padding-bottom: 20px;
        span{
          font-weight: bold;
        }
        &:first-child{
          display: flex;
          flex-direction: column;
          padding-bottom: 0;
          span{
            padding-bottom: 20px;
            &:last-child{
              color: $color;
            }
          }
        }
        &:last-child{
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    .coupon-app{
      display: none;
    }
    .coupon-pc{
      display: block;
    }
  }
  .checkout-footer{
    z-index: 1;
    padding: 20px 0;
    position: sticky;
    bottom: 0;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    .amount {
      user-select: none;
      font-size: 18px;
      color: #222222;
      margin-right: 30px;
      .amount-label{
        min-width: 200px;
        text-align: end;
        .info-circle{
          cursor: pointer;
          margin-right: 10px;
        }
      }
      .original-price{
        color: #666;
        font-size: 16px;
      }
      .price{
        color: #b50000;
        font-size: 24px;
      }
      .discount{
        color: #b50000;
        font-size: 16px;
      }
     
    }
  }
}

@media (max-width:992px) {
  #pay{
    .checkout-title {
      font-size: 18px;
      margin: 20px 0 15px 0;
    }
    .checkout-content {
      padding: 0;
      border: none;
      .subtitle {
        font-size: 16px;
        padding: 10px;
        margin: 15px 0;
      }
      .product-info{
        font-size: 16px;
        padding: 0 10px 0 10px;
        .original-price{
          font-size: 12px;
          margin-left: 10px; 
        }
        .product-label{
          .label{
            font-size: 12px;
          }
        }
        li{
          padding-bottom: 10px;
          &:first-child{
            flex-wrap: wrap;
            flex-direction: row;
            padding-bottom: 10px;
            span{
              padding-bottom: 0;
              &:first-child{
                margin-right: 15px;
              }
            }
          }
          &:last-child{
            padding-top: 5px;
          }
        }
      }
      .coupon-pc{
        display: none;
      }
      .coupon-app{
        padding: 0 10px;
        color: #999999;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        .title{
          color: #222222;
          line-height: 2;
          margin-right: 10px;
        }
        .discount{
          color: #b50000;
        }
        .tips{
          font-size: 12px;
        }
        span{
          margin-right: 10px;
        }
      }
    }
    .checkout-footer{
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      align-content: center;
      .amount {
        margin-right: 10px;
        font-size: 14px;
        .amount-label{
          font-size: 14px;
          min-width: 80px;
          .info-circle{
            margin-right: 6px;
          }
        }
        .original-price{
          color: #666;
          font-size: 14px;
        }
        .price{
          font-size: 20px;
        }
        .discount{
          font-size: 14px;
        }
        @media (max-width:320px){
          font-size: 12px;
          .amount-label{
            font-size: 12px;
          }
          .price{
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>