<template>
  <div class="coupon-bar">
    <a-tabs default-active-key="1" :tabBarGutter="24">
      <a-tab-pane key="1" :tab="availableTitle">
        <div v-if="source.availableCouponNum">{{ $t('coupon.auto') }}</div>
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide class="swiper-slide" v-for="item in source.availableCouponList" :key="item.coupon_id">
            <div class="coupon-item flex" @click="onCouponSelect(item)">
              <input class="coupon-radio" type="radio" :checked="couponcode === item.couponcode"/>
              <div class="coupon-wrapper">
                <div class="coupon-inner">
                  <div class="discount flex-center-column">
                    <div class="ellipsis bold">{{item.discount}}</div>
                    <div class="ellipsis">{{item.limit_price}}</div>
                  </div>
                  <div class="describe flex-center-column">
                    <div class="ellipsis bold">{{item.name}}</div>
                    <div class="ellipsis time">{{item.start_time + '-' + item.end_time}}</div>
                    <div class="ellipsis text" :title="item.description">
                      {{item.description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <a-empty v-show="!source.availableCouponNum" :image="require('@/assets/coupons_none.png')">
          <span slot="description" class="empty-text"> {{$t('coupon.empty')}} </span>
        </a-empty>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="noAvailableTitle">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide class="swiper-slide" v-for="item in source.noAvailableCouponList" :key="item.coupon_id">
            <div class="coupon-item flex">
              <div class="coupon-wrapper disabled">
                <div class="coupon-inner">
                  <div class="discount flex-center-column">
                    <div class="ellipsis bold">{{item.discount}}</div>
                    <div class="ellipsis">{{item.limit_price}}</div>
                  </div>
                  <div class="describe flex-center-column">
                    <div class="ellipsis bold">{{item.name}}</div>
                    <div class="ellipsis time">{{item.start_time + '-' + item.end_time}}</div>
                    <div class="ellipsis text" :title="item.reason">
                      {{item.reason}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <a-empty v-show="!source.noAvailableCouponNum" :image="require('@/assets/coupons_none.png')">
          <span slot="description" class="empty-text"> {{$t('coupon.empty')}} </span>
        </a-empty>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Tabs } from 'ant-design-vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'CouponBar',
  components: {
    Swiper,
    SwiperSlide,
    [Tabs.name]: Tabs,
    [Tabs.TabPane.name]: Tabs.TabPane,
  },
  props: {
    couponcode:{
      type: String,
      default:''
    },
    source: {
      type: Object,
      default: function() {
        return {
          availableCouponList: [],
          availableCouponNum: 0,
          noAvailableCouponList: [],
          noAvailableCouponNum: 0
        };
      }
    }
  },
  computed: {
    availableTitle() {
      return `${this.$t('coupon.tab_4')} (${this.source.availableCouponNum})`
    },
    noAvailableTitle() {
      return `${this.$t('coupon.tab_5')} (${this.source.noAvailableCouponNum})`
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 40,
        freeMode: true,
        breakpoints: { 
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 1.8,
            spaceBetween: 10
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          1200: {
            slidesPerView: 2.6,
            spaceBetween: 20
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 40
          }
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
  methods: {
    onCouponSelect(item){
      this.$emit('select',item)
    }
  },
}
</script>
<style lang="scss" scoped>
.coupon-bar {
  /deep/.ant-tabs {
    .ant-tabs-bar {
      border-bottom: none;
      .ant-tabs-tab {
        color: #666666;
        font-size: 16px;
        padding-top: 0;
        &:hover {
          color: #666666;
        }
      }

      .ant-tabs-tab-active {
        color: #666666;
      }

      .ant-tabs-ink-bar {
        background-color: $color;
      }
    }
  }
  .swiper {
    .swiper-button-disabled{
      opacity: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
      padding: 0 30px;
      top: 0;
      height: 100%;
      color: $color;
      margin-top: 0;
      border-radius: 16px;
      transition: all 0.5s ease;
      &:hover {
        color: #fff;
        background-color: rgba(100, 46, 199,0.8);
      }
      &::after {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }
  .coupon-item {
    padding: 10px;
    user-select: none;
    cursor: pointer;

    .coupon-radio {
      margin-right: 14px;
    }

    .coupon-wrapper {
      flex: 1;
      height: 160px;
      background-color: #F8F4FF;
      border-radius: 10px;
      background: radial-gradient(circle at 10px, transparent 10px, #F8F4FF 0);
      background-position: 90px calc(160px / 2);
      display: flex;

      .coupon-inner {
        flex: 1;
        padding: 10px 0;
        display: flex;
        color: #5D5D5D;
        font-size: 12px;

        .ellipsis {
          width: 100%;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //控制行数
          overflow: hidden;
        }

        .bold {
          color: #B50000;
          font-size: 20px;
          font-weight: bold;
        }

        .discount {
          width: 100px;
          padding: 0 10px;
          text-align: center;
          border-right: 2px dashed #fff;
        }

        .describe {
          flex: 1;
          padding: 0 10px;

          .bold {
            color: #222222;
            font-size: 14px;
            font-weight: bold;
          }

          .time {
            margin: 2px 0;
          }

          .text {
            line-height: 20px;
            -webkit-line-clamp: 3; //控制行数
          }
        }
      }

      .status {
        width: 24px;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        writing-mode: vertical-lr;
        writing-mode: tb-lr;
        border-radius: 0 10px 10px 0;
        background-color: #8032EA;
      }
    }

    .disabled {
      background-color: #E6E6E6;
      background: radial-gradient(circle at 10px, transparent 10px, #E6E6E6 0);
      background-position: 90px -80px;

      .coupon-inner {
        .bold {
          color: #5D5D5D;
        }

        .describe {
          .text {
            color: #B50000;
          }
        }
      }

      .status {
        color: transparent;
        background-color: #E6E6E6;
      }
    }
  }
}
</style>
